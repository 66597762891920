<template>
	<div>
		<button
			v-if="
				getAssetMaps &&
					getApartmentInfo.associationStatus != 'BIEN_DESVINCULADO' &&
					useMap
			"
			:style="cssVars"
			class="main-btn"
			@click="mapInitialization"
		>
			+ Bienes complementarios
		</button>
		<v-dialog v-model="showModal" width="1100">
			<v-card>
				<v-card-title class="text-h5 grey lighten-2">
					<v-container fluid>
						<v-row>
							<v-col class="pr-0">
								<p>
									Seleccionar bienes
									<br />
									<span style="font-size: small; "
										>Haz click en el plano a aquellos bienes que quieres agregar
										en tu cotización</span
									>
									<button
										type="button"
										aria-label="Close"
										class="close float-right"
										transparent
										@click="closeModal"
									>
										<font-awesome-icon :icon="['fas', 'times-square']" />
									</button>
								</p>
							</v-col>
						</v-row>
					</v-container>
					<!-- Emulate built in modal header close button action -->
				</v-card-title>

				<v-card-text>
					<v-overlay
						:absolute="true"
						:opacity="0.1"
						align="center"
						justify="center"
						:value="!assetImage"
					/>
					<v-container fluid>
						<v-row>
							<v-col cols="9" style="overflow: hidden;">
								<div id="asset-container">
									<inline-svg
										v-if="assetImage"
										id="asset-image"
										:key="assetMapKey"
										:src="assetImage"
										@loaded="setSvgTriggers()"
									/>
								</div>
							</v-col>
							<v-col cols="3">
								<h4>Seleccionar piso</h4>
								<v-select
									v-model="selected"
									class="my-2"
									outlined
									dense
									:items="getAssetMaps"
									@input="changeImage()"
								></v-select>
								<v-btn-toggle dense background-color="primary" dark multiple>
									<v-btn id="zoom-out">
										<font-awesome-icon :icon="['fas', 'search-minus']" />
									</v-btn>
									<v-btn id="zoom-reset">REINICIAR</v-btn>
									<v-btn id="zoom-in">
										<font-awesome-icon :icon="['fas', 'search-plus']" />
									</v-btn>
								</v-btn-toggle>
								<v-switch
									v-if="projectData.warehouseService"
									v-model="showWarehouses"
									hide-details="true"
									dense
									class="pb-1 ma-0"
									color="green lighten-2"
									@change="
										showWarehouses
											? setSubAssetsTypes({ action: 'add', type: 'BODEGA' })
											: setSubAssetsTypes({ action: 'remove', type: 'BODEGA' })
									"
								>
									<template v-slot:label>
										<span style="color: black"
											>Mostrar bodegas disponibles</span
										>
									</template>
								</v-switch>
								<v-switch
									v-if="projectData.parkingService"
									v-model="showParkingLots"
									hide-details="true"
									dense
									class="pb-1 ma-0"
									color="green lighten-2"
									@change="
										showParkingLots
											? setSubAssetsTypes({
													action: 'add',
													type: 'ESTACIONAMIENTO',
											  })
											: setSubAssetsTypes({
													action: 'remove',
													type: 'ESTACIONAMIENTO',
											  })
									"
								>
									<template v-slot:label>
										<span style="color: black"
											>Mostrar estacionamientos disponibles</span
										>
									</template>
								</v-switch>
								<h3 v-if="selectedAssets" class="mt-5 text-center">
									Bienes seleccionados
								</h3>
								<v-simple-table>
									<tr v-for="item in selectedAssets" :key="'asset' + item.id">
										<th>
											{{ item[propertyNames.assetType] }}
										</th>
										<td class="text-right">
											{{ item[propertyNames.assetNumber] }}
										</td>
										<td class="text-right">
											<v-btn
												class="my-1"
												color="error"
												small
												@click="removeItem(item[propertyNames.id])"
											>
												<font-awesome-icon :icon="['fas', 'trash']" />
											</v-btn>
										</td>
									</tr>
								</v-simple-table>

								<v-btn class="add-assets-btn" block @click="closeModal">
									+ Agregar bienes
								</v-btn>
								<v-alert
									v-model="showMaxNumberAlert"
									color="error"
									class="my-3"
								>
									<h4>Error</h4>
									<div v-if="subAssetsGlobalMaxNumber == null">
										<p
											v-if="warehouseDisabled"
											class="text-justify"
											style="font-size: 16px;"
										>
											No puede seleccionar más de
											{{ warehousesMaxNumber }} bodega(s)
										</p>
										<p
											v-if="parkingDisabled"
											class="text-justify"
											style="font-size: 16px;"
										>
											No puede seleccionar más de
											{{ parkingLotsMaxNumber }} estacionamiento(s)
										</p>
									</div>
									<p v-else class="text-justify" style="font-size: 16px;">
										No puede seleccionar más de
										{{ subAssetsGlobalMaxNumber }} bienes complementarios para
										cotizar este bien.
									</p>
								</v-alert>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog :value="showSubAssetHasMasterAsset" width="500">
			<v-card>
				<v-card-title class="headline grey lighten-2">
					Estimado cliente
				</v-card-title>

				<v-card-text>
					No es posible seleccionar este bien, ya que es parte de un bien
					maestro.
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="showSubAssetHasMasterAsset = false"
					>
						Aceptar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
export default {
	name: 'AssetMap',
	props: {
		initialAssets: { type: Array, default: () => [] },
		color: { type: String, default: null },
	},
	data() {
		return {
			clickedElement: null,
			showSvg: false,
			assetImage: null,
			assetTypes: [],
			images: [],
			selected: null,
			asset: null,
			selectedAssets: [],
			selectedWarehouses: [],
			selectedParkingLots: [],
			addedAssets: [],
			warehouseDisabled: false,
			parkingDisabled: false,
			showMaxNumberAlert: false,
			showModal: false,
			showSubAssetHasMasterAsset: false,
			assets: null,
			assetMapKey: 0,
			showWarehouses: true,
			showParkingLots: true,
			subAssetsGlobalMaxNumber: null,
			subAssetsGlobalMinNumber: null,
		}
	},

	computed: {
		...mapGetters('maps', [
			'getAssetMaps',
			'getSubAssets',
			'getAddedAssets',
			'getAddedParkingLots',
			'getAddedWarehouses',
			'getAssetById',
			'getSubAssetsNames',
		]),
		...mapGetters('assets', ['getApartmentInfo', 'getAssociatedStatus']),
		...mapState('general', [
			'warehousesMaxNumber',
			'warehousesMinNumber',
			'parkingLotsMaxNumber',
			'parkingLotsMinNumber',
			'projectData',
			'version',
		]),
		...mapState('maps', [
			'isMinParkingLotsNumberFulfilled',
			'isMinWarehouseNumberFulfilled',
			'useMap',
		]),
		...mapState('assets', [
			'secondaryAssetsToQuote',
			'associatedLimits',
			'propertyNames',
		]),
		cssVars() {
			return {
				'--primary-color': this.color,
			}
		},
	},
	watch: {
		async clickedElement() {
			if (this.clickedElement) {
				let asset = this.getAssetById(this.clickedElement)
				if (
					this.subAssetsGlobalMaxNumber != null &&
					this.secondaryAssetsToQuote.length == this.subAssetsGlobalMaxNumber
				) {
					this.parkingDisabled = true
					this.warehouseDisabled = true
					this.showMaxNumberAlert = true
				} else {
					if (
						this.getAssetById(this.clickedElement)[
							this.propertyNames.assetType
						] == 'ESTACIONAMIENTO'
					) {
						if (
							((this.selectedParkingLots.length < this.parkingLotsMaxNumber ||
								this.parkingLotsMaxNumber === null) &&
								this.subAssetsGlobalMaxNumber == null) ||
							(this.subAssetsGlobalMaxNumber != null && !this.parkingDisabled)
						) {
							if (
								!this.selectedAssets.find(
									selAsset =>
										selAsset[this.propertyNames.id] ==
										asset[this.propertyNames.id]
								)
							) {
								const showMessage = await this.addSecondaryAsset({
									id: asset.id,
									assetTypeName: 'parkingLot',
									filter: this.parkingDisabled,
									version: this.version,
								})
								if (showMessage) {
									this.showSubAssetHasMasterAsset = true
								} else {
									this.selectedAssets.push(
										this.getAssetById(this.clickedElement)
									)
									this.selectedParkingLots.push(
										this.getAssetById(this.clickedElement)
									)
								}
							}
						} else {
							if (this.subAssetsGlobalMaxNumber == null) {
								this.parkingDisabled = true
								this.showMaxNumberAlert = true
							}
						}
					} else if (
						this.getAssetById(this.clickedElement)[
							this.propertyNames.assetType
						] == 'BODEGA'
					) {
						if (
							((this.selectedWarehouses.length < this.warehousesMaxNumber ||
								this.warehousesMaxNumber === null) &&
								this.subAssetsGlobalMaxNumber == null) ||
							(this.subAssetsGlobalMaxNumber != null && !this.warehouseDisabled)
						) {
							if (
								!this.selectedAssets.find(
									selAsset =>
										selAsset[this.propertyNames.id] ==
										asset[this.propertyNames.id]
								)
							) {
								const showMessage = await this.addSecondaryAsset({
									id: asset[this.propertyNames.id],
									assetTypeName: 'warehouse',
									filter: this.warehouseDisabled,
									version: this.version,
								})
								if (showMessage) {
									this.showSubAssetHasMasterAsset = true
								} else {
									this.selectedAssets.push(
										this.getAssetById(this.clickedElement)
									)
									this.selectedWarehouses.push(
										this.getAssetById(this.clickedElement)
									)
								}
							}
						} else {
							if (this.subAssetsGlobalMaxNumber == null) {
								this.warehouseDisabled = true
								this.showMaxNumberAlert = true
							}
						}
					}
				}
			}
		},
		getAssetMaps: {
			handler: function() {
				if (this.getAssetMaps) {
					this.selected = this.getAssetMaps[0].value
					this.changeImage()
				}
			},
			immediate: true,
		},
		getSubAssets: {
			handler: function() {
				if (this.getSubAssets) {
					this.assets = this.getSubAssets
					this.changeImage()
					this.assetMapKey += 1
				}
			},
			immediate: true,
		},
		getAssociatedStatus: {
			handler: function() {
				if (this.getAssociatedStatus != null) this.resetSecondaryAssetsToQuote()
			},
			immediate: true,
		},
		associatedLimits: {
			handler: function() {
				this.subAssetsGlobalMaxNumber = this.associatedLimits.max
				this.subAssetsGlobalMinNumber = this.associatedLimits.min
			},
			immediate: true,
		},
	},
	mounted() {
		this.showParkingLots = this.showSubAssetType('ESTACIONAMIENTO')
		this.showWarehouses = this.showSubAssetType('BODEGA')
		if (!this.projectData.parkingService)
			this.setSubAssetsTypes({ action: 'remove', type: 'ESTACIONAMIENTO' })
		if (!this.projectData.warehouseService)
			this.setSubAssetsTypes({ action: 'remove', type: 'BODEGA' })
	},
	methods: {
		...mapMutations('maps', [
			'setIsMinParkingLotsNumberFulfilled',
			'setIsMinWarehouseNumberFulfilled',
			'setAddedAssets',
			'setAddedParkingLots',
			'setAddedWarehouses',
			'setSubAssetsTypes',
		]),
		...mapActions('assets', [
			'addSecondaryAsset',
			'resetSecondaryAssetsToQuote',
		]),
		...mapMutations('assets', ['removeSecondaryAssetToQuote']),
		removeItem(idBien) {
			const asset = this.getAssetById(idBien)
			const assetId = asset.id
			this.selectedAssets = this.selectedAssets.filter(
				asset => asset.id !== assetId
			)
			this.selectedWarehouses = this.selectedWarehouses.filter(
				asset => asset.id !== assetId
			)
			this.selectedParkingLots = this.selectedParkingLots.filter(
				asset => asset.id !== assetId
			)
			this.removeSecondaryAssetToQuote({
				id: assetId,
				assetTypeName:
					asset.tipoBien == 'ESTACIONAMIENTO' ? 'parkingLot' : 'warehouse',
			})
			if (this.subAssetsGlobalMaxNumber != null) {
				this.parkingDisabled = false
				this.warehouseDisabled = false
				this.showMaxNumberAlert = false
			} else {
				if (asset.tipoBien == 'ESTACIONAMIENTO') {
					this.parkingDisabled = false
				} else {
					this.warehouseDisabled = false
				}
				this.showMaxNumberAlert = false
			}
			this.paintElements()
		},
		changeImage() {
			this.assetImage = this.selected
			this.clickedElement = null
		},
		print() {
			window.print()
		},
		setZoomControls(svgDoc) {
			if (svgDoc) {
				const panzoom = this.$panzoom(svgDoc, {
					maxScale: 10,
				})
				document
					.getElementById('zoom-in')
					.addEventListener('click', panzoom.zoomIn)
				document
					.getElementById('zoom-out')
					.addEventListener('click', panzoom.zoomOut)
				document
					.getElementById('zoom-reset')
					.addEventListener('click', panzoom.reset)
				svgDoc.parentElement.addEventListener('wheel', function(event) {
					panzoom.zoomWithWheel(event)
				})
			}
		},
		setSvgTriggers() {
			if (this.assets) {
				let self = this
				self.showSvg = true
				let svgDoc = document.getElementById('asset-image')
				this.setZoomControls(svgDoc)
				let elements =
					svgDoc.querySelectorAll('#asset-elements g').length == 0
						? svgDoc.querySelector('#asset-elements').children
						: svgDoc.querySelectorAll('#asset-elements g')
				let assetElement = null
				elements.forEach(function(asset) {
					asset.id = asset.getAttribute('serif:id') || asset.id.replace('_', '')
					assetElement = self.getAssetById(asset.id)
					let drawPaths = function() {
						self.addedAssets = []
						self.paintElements()
						let path =
							typeof asset.getElementsByTagName('rect')[0] == 'undefined'
								? asset.getElementsByTagName('path')[0]
								: asset.getElementsByTagName('rect')[0]
						path = typeof path == 'undefined' ? asset : path
						if (
							path.getAttribute('data-state') == 'DISPONIBLE' &&
							self.getAssetById(this.id)
						) {
							if (
								self.selectedAssets.find(
									selAsset => selAsset.id == asset[self.propertyNames.id]
								)
							) {
								self.removeItem(parseInt(asset.id))
							} else {
								self.clickedElement = this.id
								if (
									self.getAssetById(self.clickedElement)[
										self.propertyNames.assetType
									] == 'ESTACIONAMIENTO'
								) {
									if (
										self.selectedParkingLots.length <
											self.parkingLotsMaxNumber ||
										self.parkingLotsMaxNumber === null
									) {
										path.style.fill = 'rgb(173, 216, 230)'
										path.style.fillOpacity = '.5'
									}
								}
								if (
									self.getAssetById(self.clickedElement)[
										self.propertyNames.assetType
									] == 'BODEGA'
								) {
									if (
										self.selectedWarehouses.length < self.warehousesMaxNumber ||
										self.warehousesMaxNumber === null
									) {
										path.style.fill = 'rgb(173, 216, 230)'
										path.style.fillOpacity = '.5'
									}
								}
								let assetObject = self.getAssetById(asset.id)
								if (assetObject.bConjuntos) {
									assetObject.bConjuntos
										.map(asset => asset.id)
										.forEach(function(asset) {
											if (
												!self.selectedAssets.find(
													selAsset => selAsset.id == asset.id
												)
											) {
												self.selectedAssets.push(
													self.getAssetById(self.clickedElement)
												)
											}
											let paintedElement = document.getElementById(asset)
											if (paintedElement) {
												let path =
													typeof paintedElement.getElementsByTagName(
														'rect'
													)[0] == 'undefined'
														? paintedElement.getElementsByTagName('path')[0]
														: paintedElement.getElementsByTagName('rect')[0]
												path =
													typeof path == 'undefined' ? paintedElement : path
												path.style.fill = 'rgb(173, 216, 230)'
												path.style.fillOpacity = '.5'
											}
										})
								}
							}
						}
					}
					if (assetElement) {
						asset.id = assetElement.id
						if (self.initialAssets.includes(assetElement.id)) {
							let path =
								typeof asset.getElementsByTagName('rect')[0] == 'undefined'
									? asset.getElementsByTagName('path')[0]
									: asset.getElementsByTagName('rect')[0]
							path = typeof path == 'undefined' ? asset : path
							path.setAttribute('data-color', 'rgb(108, 117, 125)')
							path.style.fill = 'rgb(108, 117, 125)'
							path.style.fillOpacity = '.5'
						} else {
							let permited = true
							let bConjuntos = []
							if (assetElement[self.propertyNames.subAsset]) {
								if (assetElement[self.propertyNames.subAsset].length > 0) {
									permited = assetElement[self.propertyNames.subAsset].filter(
										asset =>
											asset[self.propertyNames.assetType] == 'DEPARTAMENTO'
									)
										? false
										: true
									bConjuntos = assetElement[self.propertyNames.subAsset].map(
										asset => asset[self.propertyNames.id]
									)
								}
							}
							if (permited) {
								asset.style.cursor =
									assetElement[self.propertyNames.status] != 'DISPONIBLE'
										? 'not-allowed'
										: 'pointer'
								let path =
									typeof asset.getElementsByTagName('rect')[0] == 'undefined'
										? asset.getElementsByTagName('path')[0]
										: asset.getElementsByTagName('rect')[0]
								path = typeof path == 'undefined' ? asset : path
								path.style.fillOpacity = '.5'
								path.setAttribute('data-color', 'rgb(6, 224, 15)')
								path.setAttribute(
									'data-state',
									assetElement[self.propertyNames.status]
								)
								path.setAttribute(
									'data-type',
									assetElement[self.propertyNames.assetType]
								)
								if (bConjuntos.length > 0) {
									path.setAttribute('data-added', bConjuntos)
								}
								path.style.stroke = 'transparent'
								path.style.strokeWidth = '7px'
								asset.addEventListener('click', drawPaths, false)
							}
						}
					} else {
						let path =
							typeof asset.getElementsByTagName('rect')[0] == 'undefined'
								? asset.getElementsByTagName('path')[0]
								: asset.getElementsByTagName('rect')[0]
						path = typeof path == 'undefined' ? asset : path
						path.style.fill = 'transparent'
						asset.style.cursor = 'not-allowed'
					}
				}, false)
				self.paintElements()
			}
		},
		setFilter() {
			for (let color in this.filter.showColors) {
				if (!this.filter.showColors[color]) this.filter.showAllColors = false
			}
			this.paintElements()
		},
		paintElements() {
			let self = this
			let svgDoc = document.getElementById('asset-image')
			let elements =
				svgDoc.querySelectorAll('#asset-elements g').length == 0
					? svgDoc.querySelector('#asset-elements').children
					: svgDoc.querySelectorAll('#asset-elements g')
			elements.forEach(function(asset) {
				let path =
					typeof asset.getElementsByTagName('rect')[0] == 'undefined'
						? asset.getElementsByTagName('path')[0]
						: asset.getElementsByTagName('rect')[0]
				path = typeof path == 'undefined' ? asset : path
				let assetObject = self.getAssetById(asset.id)
				if (assetObject) {
					if (self.initialAssets.includes(assetObject.id)) {
						path.style.fill = 'rgb(108, 117, 125)'
					} else {
						if (assetObject.estadoBien == 'DISPONIBLE') {
							path.style.fill = 'rgb(6, 224, 15)'
						} else {
							path.style.fill = 'transparent'
						}
						if (self.selectedAssets) {
							if (
								self.selectedAssets.find(
									selAsset => selAsset.id == assetObject.id
								)
							) {
								path.style.fill = 'rgb(173, 216, 230)'
							}
						}
					}
				}
			}, false)
		},
		closeModal() {
			this.showModal = false
			this.setAddedAssets(this.selectedAssets)
			this.setAddedWarehouses(this.selectedWarehouses)
			this.setAddedParkingLots(this.selectedParkingLots)
		},
		mapInitialization() {
			this.showModal = true
			this.assetMapKey += 1
			this.selectedAssets = this.getAddedAssets
			this.selectedWarehouses = this.getAddedWarehouses
			this.selectedParkingLots = this.getAddedParkingLots
			this.showMaxNumberAlert = false
		},
		showSubAssetType(type) {
			return this.getSubAssetsNames.includes(type)
		},
	},
}
</script>
<style scoped>
.main-btn {
	border-color: 2px solid var(--primary-color) !important;
	color: var(--primary-color) !important;
	background-color: white !important;
	border-radius: 16px;
	box-shadow: 2px 2px 4px #000000;
	min-width: 220px;
	min-height: 35px;
}
.add-assets-btn {
	border-color: 2px solid #555 !important;
	color: #fff !important;
	background-color: rgb(67, 64, 64) !important;
	border-radius: 16px;
	box-shadow: 2px 2px 4px #000000;
	min-width: 220px;
	min-height: 35px;
}
</style>
