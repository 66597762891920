<template>
	<v-simple-table
		:style="
			`width:100%; ${
				backgroundColor ? `background-color: ${backgroundColor};` : ''
			}`
		"
	>
		<template v-slot:default>
			<thead>
				<tr>
					<th
						:class="
							`subtitle-1 ${textAlign ? 'text-start' : 'text-center'} ${
								withoutBorders ? 'without-borders' : ''
							}`
						"
						:style="`${headersColor ? `color: ${headersColor}; ` : ''}`"
					>
						Producto
					</th>
					<th
						v-if="!deleteItems"
						:class="
							`subtitle-1 ${textAlign ? 'text-start' : 'text-center'} ${
								withoutBorders ? 'without-borders' : ''
							}`
						"
						:style="`${headersColor ? `color: ${headersColor};` : ''}`"
					>
						Tipo bien
					</th>
					<th
						:class="
							`subtitle-1 ${textAlign ? 'text-start' : 'text-center'} ${
								withoutBorders ? 'without-borders' : ''
							}`
						"
						:style="`${headersColor ? `color: ${headersColor}; ` : ''}`"
					>
						Precio <small>({{ projectData.currencyCode }})</small>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(product, key) in uniqueAssets.filter(
						product => product != null
					)"
					:key="`${key}${product.type}`"
				>
					<td
						:class="
							`${textAlign ? 'text-start' : 'text-center'} ${
								withoutBorders ? 'without-borders' : ''
							}`
						"
					>
						<div style="display: flex !important; align-items: center;">
							<font-awesome-icon
								v-if="deleteItems && product.masterAsset"
								class="mr-2"
								:icon="['fal', 'trash-alt']"
								style="color: #AE0808;"
								@click="
									updateMasterAssetsSelected({
										action: 'remove',
										assetId: product.id,
									}),
										$emit('removeAsset', product.id)
								"
							/>
							<div
								:style="
									`${textAlign ? '' : 'width: 100%; text-align:center;'} ${
										deleteItems && !product.masterAsset
											? 'margin-left: 20px;'
											: ''
									}`
								"
							>
								{{
									product.name[0].toUpperCase() +
										product.name.substring(1).toLowerCase()
								}}
								<div v-if="product.discount && product.assetPriceWithDiscount">
									<p
										:style="
											`font-style: italic; color:${colorName(
												product.discount.color
											)}`
										"
									>
										Precio con descuento
									</p>
								</div>
							</div>
						</div>
					</td>
					<td
						v-if="!deleteItems"
						:class="
							`${textAlign ? 'text-start' : 'text-center'} ${
								withoutBorders ? 'without-borders' : ''
							}`
						"
					>
						{{ product.type == 'EMPTY' ? '' : product.type }}
					</td>
					<td
						:class="
							`${textAlign ? 'text-start' : 'text-center'} ${
								withoutBorders ? 'without-borders' : ''
							}`
						"
					>
						<p
							:style="
								`${
									product.assetPriceWithDiscount && product.discount
										? 'text-decoration: line-through red 1px;'
										: ''
								} `
							"
						>
							{{ formatNumber(product.price) }}
						</p>
						<div v-if="product.assetPriceWithDiscount && product.discount">
							<p
								:style="
									`font-style: italic; color:${colorName(
										product.discount.color
									)}`
								"
							>
								{{ formatNumber(product.assetPriceWithDiscount) }}
							</p>
						</div>
					</td>
				</tr>
				<tr
					v-if="showTotal"
					:style="
						`${
							fromMainview
								? `background-color:${
										themeColors.totalColor
											? themeColors.totalColor
											: themeColors.color3
								  }; color: ${themeColors.buttonTextColor};`
								: `background-color:${themeColors.infoAlertColor}; color:${themeColors.infoAlertTextColor};`
						} width: 100%`
					"
					:class="` ${withoutBorders ? 'total-focus' : ''}`"
				>
					<td
						:class="
							`${textAlign ? 'text-start' : 'text-center'} font-weight-black`
						"
					>
						<b>TOTAL</b>
					</td>
					<td v-if="!deleteItems"></td>
					<td
						:class="
							`${textAlign ? 'text-start' : 'text-center'} font-weight-black`
						"
					>
						{{ formatNumber(getFinalAssetsAmount) }}
					</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
	name: 'TableSubAssets',
	props: {
		showTotal: {
			type: Boolean,
			default: true,
		},
		fromMainview: {
			type: Boolean,
			default: false,
		},
		backgroundColor: {
			type: String,
			default: null,
		},
		headersColor: {
			type: String,
			default: null,
		},
		withoutBorders: {
			type: Boolean,
			default: false,
		},
		deleteItems: {
			type: Boolean,
			default: false,
		},
		textAlign: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters('assets', ['getAssetInfo', 'getFinalAssetsAmount']),
		...mapState('general', ['projectData']),
		...mapState('theme', ['themeColors']),
		uniqueAssets() {
			const uniqueIds = new Set()
			return this.getAssetInfo.filter(item => {
				if (item)
					if (!uniqueIds.has(item.name)) {
						uniqueIds.add(item.name)
						return true
					}
				return false
			})
		},
	},
	methods: {
		colorName(color) {
			if (color == 'Verde') {
				return 'green'
			} else if (color == 'Rojo') {
				return 'red'
			} else {
				return 'blue'
			}
		},
		formatNumber(numberToFormat) {
			let response = ''
			if (this.projectData.currencySymbolPosition == 'ANTES_CANTIDAD')
				response += this.projectData.currencySymbol + ' '
			let numberToFormatArray = numberToFormat.toString().split('.')
			response += numberToFormatArray[0].replace(
				/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
				this.projectData.currencyThousandSeparator
			)
			if (
				numberToFormatArray.length > 1 &&
				this.projectData.currencyDecimalSeparator !== 'N'
			) {
				response +=
					this.projectData.currencyDecimalSeparator + numberToFormatArray[1]
			}
			if (this.projectData.currencySymbolPosition == 'DESPUES_CANTIDAD')
				response += ' ' + this.projectData.currencySymbol
			return response
		},
		...mapActions('assets', ['updateMasterAssetsSelected']),
	},
}
</script>
<style scoped>
.v-application p {
	margin: 0px;
}
.without-borders {
	border: none !important;
}
.total-focus {
	background-color: #b7e5ee;
}
</style>
