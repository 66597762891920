import Vue from 'vue'

const state = {
	assetMaps: null,
	buildingMaps: null,
	propertyNames: {
		subAssetTypes: ['BODEGA', 'ESTACIONAMIENTO'],
		assetType: 'tipoBien',
		id: 'id',
		assetNumber: 'numeroDeBien',
	},
	addedAssets: [],
	addedParkingLots: [],
	addedWarehouses: [],
	useMap: false,
	useBuildingMap: false,
	isMinParkingLotsNumberFulfilled: false,
	isMinWarehouseNumberFulfilled: false,
}

const mutations = {
	setAssetMaps: (state, data) => (state.assetMaps = data),
	setBuildingMaps: (state, data) => (state.buildingMaps = data),
	setAddedAssets: (state, data) => (state.addedAssets = data),
	setUseMap: (state, data) => (state.useMap = data),
	setUseBuildingMap: (state, data) => (state.useBuildingMap = data),
	setIsMinParkingLotsNumberFulfilled: (state, payload) =>
		(state.isMinParkingLotsNumberFulfilled = payload),
	setIsMinWarehouseNumberFulfilled: (state, payload) =>
		(state.isMinWarehouseNumberFulfilled = payload),
	setAddedParkingLots: (state, data) => (state.addedParkingLots = data),
	setAddedWarehouses: (state, data) => (state.addedWarehouses = data),
	setSubAssetsTypes: (state, data) => {
		if (data.action == 'remove') {
			state.propertyNames.subAssetTypes = state.propertyNames.subAssetTypes.filter(
				assetType => assetType != data.type
			)
		} else {
			state.propertyNames.subAssetTypes.push(data.type)
		}
	},
	setSecondaryAssetsData: state => {
		state.addedAssets = []
		state.addedParkingLots = []
		state.addedWarehouses = []
	},
}
const getters = {
	getAssetMaps: state => state.assetMaps,
	getBuildingMaps: state => state.buildingMaps,
	getSubAssets: (_state, getters, rootState, rootGetters) => {
		let assets = []
		if (rootState.assets.associatedStatus == 'BIEN_ASOCIADO') {
			assets = rootGetters['assets/getMainAssetSubAssets']
		} else {
			assets = rootState.assets.fetchedAssets
		}
		return assets.filter(
			asset =>
				state.propertyNames.subAssetTypes.includes(
					asset[rootState.assets.propertyNames.assetType]
				) &&
				!(
					asset[rootState.assets.propertyNames.associationType] &&
					asset[rootState.assets.propertyNames.associationType] ==
						'BIEN_DESVINCULADO'
				)
		)
	},
	getAddedAssets: state => state.addedAssets,
	getAddedParkingLots: (_state, getters, rootState, rootGetters) =>
		rootState.assets.secondaryAssetsToQuote
			.map(asset => {
				return rootGetters['assets/getAssetById'](
					asset[rootState.assets.propertyNames.id]
				)
			})
			.filter(
				asset =>
					asset[rootState.assets.propertyNames.assetType] == 'ESTACIONAMIENTO'
			),
	getAddedWarehouses: (_state, getters, rootState, rootGetters) =>
		rootState.assets.secondaryAssetsToQuote
			.map(asset => {
				return rootGetters['assets/getAssetById'](
					asset[rootState.assets.propertyNames.id]
				)
			})
			.filter(
				asset => asset[rootState.assets.propertyNames.assetType] == 'BODEGA'
			),
	getAssetById: (state, getters, rootState) => id => {
		let asset = getters.getSubAssets.find(
			asset => asset[rootState.assets.propertyNames.id] == id
		)
		return asset
			? asset
			: getters.getSubAssets.find(
					asset =>
						asset[rootState.assets.propertyNames.assetType] +
							asset[rootState.assets.propertyNames.assetNumber] ==
						id
			  )
	},
	getSubAssetsNames: state => state.propertyNames.subAssetTypes,
}
const actions = {
	async fetchAssetMaps({ rootState, commit }) {
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		await Vue.axios
			.get(`${version}/quotes/get-asset-map-images`, {
				params: {
					real_estate: rootState.general.realEstate,
					project_id: rootState.general.projectId,
				},
			})
			.then(response => {
				if (!response.data.length) {
					//console.log('[MobySuite Quote] Map images not found')
				} else {
					commit('setAssetMaps', response.data)
					commit('setUseMap', true)
				}
			})
			.catch(error => {
				console.log(error)
			})
	},
	async fetchBuildingMaps({ rootState, commit }) {
		const version = rootState.general.version == 2 ? 'v3' : 'v2'
		await Vue.axios
			.get(`${version}/quotes/get-building-map-images`, {
				params: {
					real_estate: rootState.general.realEstate,
					project_id: rootState.general.projectId,
				},
			})
			.then(response => {
				if (!response.data.length) {
					console.log('[MobySuite Quote] Building images not found')
				} else {
					commit('setBuildingMaps', response.data)
					commit('setUseBuildingMap', true)
				}
			})
			.catch(error => {
				console.log(error)
			})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
