<template>
	<div class="d-block">
		<v-autocomplete
			hide-details
			class="ma-1"
			dense
			outlined
			:dark="theme"
			:attach="true"
			:value="getSelectedAssetValue"
			:items="getSelectItems"
			:label="getLabel"
			:background-color="backgroundColor"
			:color="color"
			:style="`color:${themeColors.selectTextColor}`"
			@change="updateSelection"
		>
			<template v-slot:selection="{ item }">
				<span v-if="item.text" style="margin-right: 10px;" v-text="item.text">
				</span>
				<span v-else v-text="item"></span>
				<span v-if="item.discountInfo != null">
					<span
						v-if="
							item.discountInfo[propertyNames.discountType] == 'GRUPO_BIENES'
						"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="` Reserva hoy con `"
					>
					</span>
					<span
						v-if="item.discountInfo[propertyNames.amountType] == 'PORCENTAJE'"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento ${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(
								item.discountInfo[propertyNames.discountAmount] * 100
							)}%`
						"
					></span>
					<span
						v-if="item.discountInfo[propertyNames.amountType] == 'CONSTANTE'"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento ${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(item.discountInfo[propertyNames.discountAmount])} UF`
						"
					></span>
					<span
						v-if="item.discountInfo[propertyNames.amountType] == 'MONTO'"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento $${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(item.discountInfo[propertyNames.discountAmount])}`
						"
					></span>
					<span
						v-if="
							item.discountInfo[propertyNames.amountType] ==
								'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
								item[propertyNames.nonAuthorizedDiscount] != null
						"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento ${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(item[propertyNames.nonAuthorizedDiscount] * 100)}%`
						"
					></span>
					<span
						v-if="
							item.discountInfo[propertyNames.amountType] ==
								'MONTO_DESCUENTO_BIEN' &&
								item[propertyNames.authorizedDiscount] != null
						"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento ${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(item[propertyNames.authorizedDiscount] * 100)}%`
						"
					></span>
				</span>
			</template>
			<template v-slot:item="{ item }">
				<span v-if="item.text" style="margin-right: 10px;" v-text="item.text">
				</span>
				<span v-else v-text="item"></span>
				<span v-if="item.discountInfo != null">
					<span
						v-if="
							item.discountInfo[propertyNames.discountType] == 'GRUPO_BIENES'
						"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="` Reserva hoy con `"
					>
					</span>
					<span
						v-if="item.discountInfo[propertyNames.amountType] == 'PORCENTAJE'"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento ${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(
								item.discountInfo[propertyNames.discountAmount] * 100
							)}%`
						"
					></span>
					<span
						v-if="item.discountInfo[propertyNames.amountType] == 'CONSTANTE'"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento ${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(item.discountInfo[propertyNames.discountAmount])} UF`
						"
					></span>
					<span
						v-if="item.discountInfo[propertyNames.amountType] == 'MONTO'"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento $${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(item.discountInfo[propertyNames.discountAmount])}`
						"
					></span>
					<span
						v-if="
							item.discountInfo[propertyNames.amountType] ==
								'MONTO_DESCUENTO_NO_AUTORIZADO_BIEN' &&
								item[propertyNames.nonAuthorizedDiscount] != null
						"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento ${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(item[propertyNames.nonAuthorizedDiscount] * 100)}%`
						"
					></span>
					<span
						v-if="
							item.discountInfo[propertyNames.amountType] ==
								'MONTO_DESCUENTO_BIEN' &&
								item[propertyNames.authorizedDiscount] != null
						"
						:style="
							`margin-left: 3px; color:${colorName(item.discountInfo.color)}`
						"
						v-text="
							`descuento ${new Intl.NumberFormat('es-CL', {
								maximumFractionDigits: 2,
							}).format(item[propertyNames.authorizedDiscount] * 100)}%`
						"
					></span>
				</span>
			</template>
		</v-autocomplete>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
	name: 'SelectTriangular',
	props: {
		theme: { type: Boolean, default: false },
		assetPropertyName: { type: String, default: 'apartment' },
		backgroundColor: { type: String, default: '' },
		color: { type: String, default: '' },
	},
	computed: {
		...mapState('theme', ['themeColors']),
		...mapGetters('neourbe', [
			'getAllBathroomsAmountsWithAllWildcard',
			'getAllRoomsAmountsWithAllWildcard',
			'getApartmentsBySelectedRoomsAndBathroomsAmounts',
		]),
		...mapState('neourbe', ['selectedBathroomsAmount', 'selectedRoomsAmount']),
		...mapState('assets', [
			'apartmentId',
			'masterAssetType',
			'propertyNames',
			'orderByPrice',
		]),
		...mapState('general', ['assetIdParam', 'projectData']),
		getSelectItems() {
			if (
				this.assetPropertyName == 'rooms' ||
				this.assetPropertyName == 'rooms_mx'
			)
				return this.getAllRoomsAmountsWithAllWildcard
			else if (this.assetPropertyName == 'bathrooms')
				return this.getAllBathroomsAmountsWithAllWildcard
			else {
				let sortedData = this.getApartmentsBySelectedRoomsAndBathroomsAmounts
				if (this.orderByPrice) {
					sortedData = sortedData.sort((a, b) => {
						return a.price - b.price
					})
				}
				return sortedData
			}
		},
		getSelectedAssetValue() {
			if (this.assetPropertyName == 'rooms') return this.selectedRoomsAmount
			if (this.assetPropertyName == 'rooms_mx') return this.selectedRoomsAmount
			else if (this.assetPropertyName == 'bathrooms')
				return this.selectedBathroomsAmount
			else {
				if (this.assetIdParam) {
					return this.getApartmentsBySelectedRoomsAndBathroomsAmounts.find(
						selAp => selAp.value == this.assetIdParam
					)
						? this.assetIdParam
						: this.apartmentId
				} else {
					return this.apartmentId
				}
			}
		},
		getLabel() {
			if (this.assetPropertyName == 'rooms') return 'Dormitorios'
			if (this.assetPropertyName == 'rooms_mx') return 'Recámaras'
			else if (this.assetPropertyName == 'bathrooms') return 'Baños'
			else {
				const lowerCaseName = this.masterAssetType.join(',').toLowerCase()
				const capitalizedName =
					lowerCaseName.charAt(0).toUpperCase() + lowerCaseName.slice(1)
				return capitalizedName
			}
		},
	},
	methods: {
		...mapActions('neourbe', ['triangularSelection']),
		updateSelection(value) {
			this.$emit('reset-data', this.assetPropertyName)
			this.triangularSelection({ property: this.assetPropertyName, value })
		},
		colorName(color) {
			if (color == 'Verde') {
				return 'green'
			} else if (color == 'Rojo') {
				return 'red'
			} else {
				return 'blue'
			}
		},
		formatNumber(numberToFormat) {
			let response = ''
			if (this.projectData.currencySymbolPosition == 'ANTES_CANTIDAD')
				response += this.projectData.currencySymbol + ' '
			let numberToFormatArray = numberToFormat.toString().split('.')
			response += numberToFormatArray[0].replace(
				/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
				this.projectData.currencyThousandSeparator
			)
			if (
				numberToFormatArray.length > 1 &&
				this.projectData.currencyDecimalSeparator !== 'N'
			) {
				response +=
					this.projectData.currencyDecimalSeparator + numberToFormatArray[1]
			}
			if (this.projectData.currencySymbolPosition == 'DESPUES_CANTIDAD')
				response += ' ' + this.projectData.currencySymbol
			return response
		},
	},
}
</script>
