<template>
	<v-container
		fluid
		:style="
			`background-color:${themeColors.color1}; color:${themeColors.color2};`
		"
	>
		<v-row v-if="subTypes" class="mt-2">
			<v-col v-if="!hideProjectData" class="text-left" cols="12">
				<p
					class="title b text-left"
					:style="{
						color: themeColors.color3,
						fontSize: '25px !important',
						fontWeight: '900',
						marginBottom: '0px',
					}"
				>
					COTIZACIÓN
				</p>
				<p
					class="subtitle-1 b text-left"
					:style="{
						fontSize: '20px !important',
						fontWeight: '500',
						marginBottom: '0px',
					}"
				>
					{{ projectData.projectName }}
					{{ projectData.commune ? `• ${projectData.commune}` : '' }}
				</p>
				<p
					class="subtitle-1 b text-left"
					:style="{
						fontSize: '18px !important',
						fontWeight: '400',
						marginTop: '40px',
						marginBottom: '0px',
					}"
				>
					{{ description }}
				</p>
			</v-col>
			<v-col cols="12" class="pb-10">
				<v-btn-toggle
					v-model="selectedSubTypeId"
					class="d-flex flex-row select-type-btn"
					group
					border
					@change="filterSubType"
				>
					<v-btn
						v-for="subType in subTypes"
						:key="`sub-type${subType.id}`"
						:value="subType.id"
						:class="{ active: subType.id == selectedSubTypeId }"
						class="flex-grow-1 category-filter-btn"
						size="large"
					>
						{{ subType.value }}
					</v-btn>
				</v-btn-toggle>
			</v-col>
			<v-col cols="12" sm="12" md="5">
				<v-row class="d-block">
					<v-col cols="12" sm="12" md="9">
						<v-select
							v-model="selectedFilterId"
							class="ma-1 quote-select-squared"
							outlined
							dense
							hide-details
							:items="roomBathFilters"
							item-value="value"
							:return-object="false"
							append-icon="⌄"
							:attach="true"
							@change="filterData"
						>
							<template v-slot:item="{ item }">
								<span style="margin-right: 10px;">
									{{ item.text }}
								</span>
							</template>
							<template v-slot:selection="{ item }">
								<span style="margin-right: 10px;">
									{{ item.text }}
								</span>
							</template>
						</v-select>
						<ul class="ma-1 quote-apartment-list">
							<li
								v-for="apartment in apartments"
								:key="apartment[propertyNames.id]"
								:class="{
									selected: selectedApartmentId == apartment[propertyNames.id],
								}"
								@click="
									selectedApartmentId = apartment[propertyNames.id]
									updateApartmentId()
								"
							>
								{{
									`Tipo ${apartment[propertyNames.type]} · ${
										apartment[propertyNames.roomsAmount]
									}D + ${apartment[propertyNames.bathroomsAmount]}B `
								}}
								<b>
									·
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(apartment[propertyNames.utilSurface])
									}}
									m<sup>2</sup>
								</b>
							</li>
						</ul>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col class="text-left" cols="12" sm="12" md="9">
						<template v-if="getApartmentInfo.assetType == 'SITIO'">
							<div class="filter-category-title my-4 font-weight-black">
								{{ getApartmentInfo.assetType }}
							</div>
						</template>
						<template v-else>
							<div class="filter-category-title my-4 font-weight-black">
								{{ getApartmentInfo.rooms }} DORM{{
									getApartmentInfo.rooms > 1 ? 'S' : ''
								}}. {{ getApartmentInfo.bathrooms }} BAÑO{{
									getApartmentInfo.bathrooms > 1 ? 'S' : ''
								}}
								<br />
								{{ getApartmentInfo.assetType }} TIPO
								{{ getApartmentInfo.type }}
							</div>
						</template>
						<div class="filter-category-subtitle">
							Superficie <br />
							<template v-if="getApartmentInfo.assetType !== 'SITIO'">
								Interior:
								{{
									new Intl.NumberFormat('es-CL', {
										maximumFractionDigits: 2,
									}).format(getApartmentInfo.inner)
								}}
								m<sup>2</sup> <br />
								<template
									v-if="
										getApartmentInfo.terrain != null &&
											getApartmentInfo.terrain != '0' &&
											getApartmentInfo.assetType == 'CASA'
									"
								>
									Terreno:
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(getApartmentInfo.terrain)
									}}
									m<sup>2</sup>
									<br />
								</template>

								<template
									v-else-if="
										getApartmentInfo.terrace != null &&
											getApartmentInfo.terrace != '0'
									"
								>
									Terrazas:
									{{
										new Intl.NumberFormat('es-CL', {
											maximumFractionDigits: 2,
										}).format(getApartmentInfo.terrace)
									}}
									m<sup>2</sup>
									<br />
								</template>
							</template>
							Total:
							{{
								new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
								}).format(getApartmentInfo.surface)
							}}
							m<sup>2</sup> <br />
							Útil:
							{{
								new Intl.NumberFormat('es-CL', {
									maximumFractionDigits: 2,
								}).format(getApartmentInfo.util)
							}}
							m<sup>2</sup> <br />
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<p v-if="quoteLimit != null && quoteLimit <= 0" style="color: red">
							Ha excedido el número de cotizaciones permitidas por día.
						</p>
						<v-btn
							id="moby-quote-button"
							min-height="60px"
							:color="themeColors.color3"
							class="my-2 rounded-0"
							block
							x-large
							:style="
								`color: ${
									quoteLimit != null && quoteLimit <= 0
										? '#000'
										: themeColors.buttonTextColor
								}; font-size: 18px`
							"
							@click="showModal = true"
						>
							{{ textButton }}
						</v-btn>
					</v-col>
					<v-col v-if="getOpportunityStatus && !reservation">
						<v-btn
							id="moby-reservation-button"
							:color="themeColors.color3"
							x-large
							min-height="60px"
							block
							class="my-2 rounded-0"
							:style="`color: ${themeColors.buttonTextColor}; font-size: 18px`"
							@click="showModal = true"
						>
							Reservar
						</v-btn>
					</v-col>
				</v-row>
			</v-col>
			<v-col cols="12" sm="12" md="7">
				<v-row align="center">
					<v-col>
						<image-dialog
							:thumb="`${getApartmentInfo.image}`"
							:full="`${getApartmentInfo.image}`"
							:mobile="
								this.$vuetify.breakpoint.name == 'xs' ||
								this.$vuetify.breakpoint.name == 'sm'
									? true
									: false
							"
							:style="
								`position:relative; max-height: 400, max-width: ${imgWidth};`
							"
						></image-dialog>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-dialog v-model="showModal" width="1100">
			<v-card class="filter-per-category">
				<v-card-text class="px-0 py-0">
					<v-container fluid>
						<v-row>
							<v-col cols="12" md="6" class="py-16">
								<image-dialog
									:thumb="`${getApartmentInfo.image}`"
									:full="`${getApartmentInfo.image}`"
									:mobile="
										this.$vuetify.breakpoint.name == 'xs' ||
										this.$vuetify.breakpoint.name == 'sm'
											? true
											: false
									"
									:style="
										`position:relative; max-height: 400, max-width: ${imgWidth};`
									"
								></image-dialog>
								<v-row align="center" justify="center">
									<v-col class="text-left" cols="10" offset="1">
										<h2
											v-if="getApartmentInfo.assetType == 'SITIO'"
											class="data-title my-2 font-weight-black"
										>
											{{ getApartmentInfo.assetType }}
										</h2>
										<h2 v-else class="data-title my-2 font-weight-black">
											{{ getApartmentInfo.assetType }} TIPO
											{{ getApartmentInfo.type }}
										</h2>
									</v-col>
									<v-col cols="10" offset="1">
										<table class="data-table">
											<tr v-if="getApartmentInfo.assetType !== 'SITIO'">
												<td>
													<font-awesome-icon
														:icon="['fal', 'bed-alt']"
														size="lg"
														class="mr-2"
													/>
													{{ getApartmentInfo.rooms }} dorm{{
														getApartmentInfo.rooms > 1 ? 's' : ''
													}}.
												</td>
												<td>
													Sup. Terraza:
													{{
														new Intl.NumberFormat('es-CL', {
															maximumFractionDigits: 2,
														}).format(getApartmentInfo.terrace)
													}}
													m<sup>2</sup>
												</td>
											</tr>
											<tr>
												<td></td>
												<td>
													Sup. Total:
													{{
														new Intl.NumberFormat('es-CL', {
															maximumFractionDigits: 2,
														}).format(getApartmentInfo.surface)
													}}
													m<sup>2</sup>
												</td>
											</tr>
											<tr>
												<td v-if="getApartmentInfo.assetType == 'SITIO'"></td>
												<td v-else>
													<font-awesome-icon
														:icon="['fal', 'bath']"
														size="lg"
														class="mr-2"
													/>
													{{ getApartmentInfo.bathrooms }} baño{{
														getApartmentInfo.bathrooms > 1 ? 's' : ''
													}}
												</td>
												<td>
													Sup. Útil:
													{{
														new Intl.NumberFormat('es-CL', {
															maximumFractionDigits: 2,
														}).format(getApartmentInfo.util)
													}}
													m<sup>2</sup>
												</td>
											</tr>
										</table>
									</v-col>
								</v-row>
							</v-col>
							<v-col
								v-if="sendingQuote"
								cols="12"
								md="6"
								style="background-color: #2e2e2e;"
							>
								<v-overlay
									:absolute="false"
									:opacity="0.1"
									align="center"
									justify="center"
									style="position: static;"
									:value="sendingQuote"
								>
									<h1 class="font-weight-thin white--text my-2">
										Enviando
									</h1>
									<v-progress-circular
										:color="themeColors.color3"
										indeterminate
										size="40"
										class="my-3"
									/>
									<p class="subtitle-1 white--text">
										Por favor, espere
									</p>
								</v-overlay>
							</v-col>
							<v-col
								v-else-if="
									quoteNotification.success &&
										!captchaError &&
										formSubmissionResponded
								"
								cols="12"
								md="6"
								style="background-color: #2e2e2e;"
							>
								<v-container fluid fill-height>
									<view-success-payment @resetQuote="resetQuote" />
								</v-container>
							</v-col>
							<v-col v-else cols="12" md="6" style="background-color: #2e2e2e;">
								<v-container fluid fill-height>
									<v-row align="center">
										<v-col cols="10" offset="1">
											<quote-form
												ref="formRef"
												class="mt-4"
												sm-size="12"
												:text-field-outlined="true"
												:select-outlined="true"
												:is-squared="true"
											/>
										</v-col>
										<v-col cols="10" offset="1">
											<p
												v-if="quoteLimit != null && quoteLimit <= 0"
												style="color: red"
											>
												Ha excedido el número de cotizaciones permitidas por
												día.
											</p>
											<v-btn
												id="moby-quote-button"
												min-height="60px"
												:color="themeColors.color3"
												class="my-2 rounded-0"
												block
												x-large
												:style="
													`color: ${
														quoteLimit != null && quoteLimit <= 0
															? '#000'
															: themeColors.buttonTextColor
													}; font-size: 18px`
												"
												:disabled="
													quoteLimit != null && quoteLimit <= 0 ? true : false
												"
												@click="$refs.formRef.submitForm()"
											>
												{{ textButton }}
											</v-btn>
										</v-col>
										<v-col
											v-if="getOpportunityStatus && !reservation"
											cols="12"
											xs="12"
											sm="9"
										>
											<v-btn
												id="moby-reservation-button"
												:color="themeColors.color3"
												x-large
												min-height="60px"
												block
												class="mt-2 ml-sm-2"
												:style="
													`color: ${themeColors.buttonTextColor}; font-size: 16px`
												"
												@click="$refs.formRef.submitForm(true)"
											>
												Reservar
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import QuoteForm from '@/components/QuoteForm.vue'
import ImageDialog from '@/components/ImageDialog.vue'
import ViewSuccessPayment from '@/components/ViewSuccessPayment.vue'

export default {
	name: 'Simple',
	components: {
		QuoteForm,
		ImageDialog,
		ViewSuccessPayment,
	},
	data() {
		return {
			selectedFilterId: null,
			selectedApartmentId: null,
			selectedSubTypeId: null,
			roomBathFilters: null,
			apartments: null,
			showModal: false,
			subTypes: null,
		}
	},
	computed: {
		...mapGetters('assets', [
			'getApartmentsByTypology',
			'getRoomBathFilters',
			'getApartmentInfo',
			'getOpportunityStatus',
		]),
		...mapState('assets', ['propertyNames']),
		...mapState('theme', ['themeColors', 'hideProjectData', 'innerSuccess']),
		...mapState('general', [
			'quoteLimit',
			'reservation',
			'projectData',
			'quoteNotification',
			'formSubmissionResponded',
			'description',
		]),
		...mapState('form', ['captchaError', 'sendingQuote']),
		imgWidth() {
			var w = ''
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					w = '90%'
					break
				default:
					w = 600
			}
			return w
		},
		textButton() {
			return this.reservation ? 'Reservar' : 'Cotizar'
		},
	},
	watch: {
		projectData: {
			handler: function() {
				if (this.projectData.subTypes) {
					this.setSubTypes()
				}
			},
			immediate: true,
		},
	},
	created() {
		this.setSuccessViewType('payment')
		this.setInnerSuccess(true)
		this.setSubTypes()
		this.selectedSubTypeId = this.subTypes[0].id
		this.apartments = this.getApartmentsByTypology.sort(
			(a, b) => b[this.propertyNames.price] - a[this.propertyNames.price]
		)
		this.filterSubType()
		this.selectedApartmentId = this.apartments.find(apartment => {
			if (apartment[this.propertyNames.assetSubtype])
				return (
					apartment[this.propertyNames.assetSubtype].id ==
					this.selectedSubTypeId
				)
		})[this.propertyNames.id]
	},
	mounted() {
		let event = new Event('template-ready', { bubbles: true })
		document.dispatchEvent(event)
	},
	methods: {
		...mapMutations('assets', [
			'setApartmentId',
			'setAssetMasterType',
			'setOpportunityStatus',
			'setAssociatedStatus',
			'setAssociatedLimits',
		]),
		...mapMutations('theme', ['setSuccessViewType', 'setInnerSuccess']),
		...mapActions('assets', ['resetSecondaryAssetsToQuote']),
		updateApartmentId() {
			this.setApartmentId(this.selectedApartmentId)
			this.setAssetMasterType(this.getApartmentInfo.assetType)
			if (
				this.getApartmentInfo.associationStatus == 'BIEN_DESVINCULADO' ||
				this.getApartmentInfo.associationStatus == 'BIEN_ASOCIADO'
			) {
				this.resetSecondaryAssetsToQuote()
			}
			if (this.getApartmentInfo.discount) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'GRUPO_BIENES'
				) {
					this.setOpportunityStatus(true)
				} else {
					this.setOpportunityStatus(false)
				}
			} else {
				this.setOpportunityStatus(false)
			}
			if (this.getApartmentInfo.associationStatus) {
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_DESVINCULADO'
				)
					this.setAssociatedStatus('BIEN_DESVINCULADO')
				if (
					this.getApartmentInfo.discount[this.propertyNames.discountType] ==
					'BIEN_ASOCIADO'
				) {
					this.setAssociatedStatus('BIEN_ASOCIADO')
					const obj = {
						max: this.getApartmentInfo.associationLimits.max,
						min: this.getApartmentInfo.associationLimits.min,
					}
					this.setAssociatedLimits(obj)
				}
			} else {
				this.setAssociatedStatus(null)
			}
		},
		resetQuote() {
			this.showModal = false
		},
		filterData() {
			const filterSubType = this.selectedSubTypeId
			const filters = this.selectedFilterId.split('-')
			this.apartments = this.getApartmentsByTypology
				.filter(apartment => {
					if (apartment[this.propertyNames.assetSubtype]) {
						return (
							apartment[this.propertyNames.assetSubtype].id == filterSubType &&
							apartment[this.propertyNames.roomsAmount] == filters[0] &&
							apartment[this.propertyNames.bathroomsAmount] == filters[1]
						)
					}
				})
				.sort(
					(a, b) => b[this.propertyNames.price] - a[this.propertyNames.price]
				)
			let self = this
			this.apartments = [
				...new Map(
					self.apartments.map(item => [
						`${item[self.propertyNames.roomsAmount]}${
							item[self.propertyNames.bathroomsAmount]
						}${item[self.propertyNames.assetType]}${
							item[self.propertyNames.type]
						}${item[self.propertyNames.utilSurface]}`,
						item,
					])
				).values(),
			]
			//this.apartments = [...new Set(apartments)]
			this.selectedApartmentId = this.apartments[0][this.propertyNames.id]
			this.updateApartmentId()
		},
		filterSubType() {
			const filterSubType = this.selectedSubTypeId
			this.apartments = this.getApartmentsByTypology
				.filter(apartment => {
					if (apartment[this.propertyNames.assetSubtype])
						return (
							apartment[this.propertyNames.assetSubtype].id == filterSubType
						)
				})
				.sort(
					(a, b) => b[this.propertyNames.price] - a[this.propertyNames.price]
				)
			let self = this
			this.apartments = [
				...new Map(
					self.apartments.map(item => [
						`${item[self.propertyNames.roomsAmount]}${
							item[self.propertyNames.bathroomsAmount]
						}${item[self.propertyNames.assetType]}${
							item[self.propertyNames.type]
						}${item[self.propertyNames.utilSurface]}`,
						item,
					])
				).values(),
			]
			if (this.apartments.length > 0) {
				this.roomBathFilters = this.setRoomBathFilters()
				this.selectedFilterId = this.roomBathFilters[0].value
				const filters = this.selectedFilterId.split('-')
				this.apartments = this.getApartmentsByTypology
					.filter(apartment => {
						if (apartment[this.propertyNames.assetSubtype])
							return (
								apartment[this.propertyNames.assetSubtype].id ==
									filterSubType &&
								apartment[this.propertyNames.roomsAmount] == filters[0] &&
								apartment[this.propertyNames.bathroomsAmount] == filters[1]
							)
					})
					.sort(
						(a, b) => b[this.propertyNames.price] - a[this.propertyNames.price]
					)

				this.apartments = [
					...new Map(
						self.apartments.map(item => [
							`${item[self.propertyNames.roomsAmount]}${
								item[self.propertyNames.bathroomsAmount]
							}${item[self.propertyNames.assetType]}${
								item[self.propertyNames.type]
							}${item[self.propertyNames.totalArea]}`,
							item,
						])
					).values(),
				]
				this.selectedApartmentId = this.apartments[0][this.propertyNames.id]
				this.updateApartmentId()
			}
		},
		colorName(color) {
			if (color == 'Verde') {
				return 'green'
			} else if (color == 'Rojo') {
				return 'red'
			} else {
				return 'blue'
			}
		},
		setRoomBathFilters() {
			let self = this
			const roomBathFilters = [
				...new Map(
					self.apartments
						.sort((a, b) => {
							if (
								a[self.propertyNames.roomsAmount] <
								b[self.propertyNames.roomsAmount]
							) {
								return -1
							} else if (
								a[self.propertyNames.roomsAmount] >
								b[self.propertyNames.roomsAmount]
							) {
								return 1
							} else {
								if (
									a[self.propertyNames.bathroomsAmount] <
									b[self.propertyNames.bathroomsAmount]
								) {
									return -1
								}
								if (
									a[self.propertyNames.bathroomsAmount] >
									b[self.propertyNames.bathroomsAmount]
								) {
									return 1
								}
							}
							return 0
						})
						.map(item => [
							`${item[self.propertyNames.roomsAmount]}-${
								item[self.propertyNames.bathroomsAmount]
							}`,
							{
								value: `${item[self.propertyNames.roomsAmount]}-${
									item[self.propertyNames.bathroomsAmount]
								}`,
								text: `${item[self.propertyNames.roomsAmount]}  dormitorio${
									item[self.propertyNames.roomsAmount] > 0 ? 's' : ''
								}
						/ ${item[self.propertyNames.bathroomsAmount]} baño${
									item[self.propertyNames.bathroomsAmount] > 0 ? 's' : ''
								}`,
							},
						])
				).values(),
			]
			return roomBathFilters
		},
		setSubTypes() {
			let self = this
			this.subTypes = this.projectData.subTypes.filter(subType =>
				self.getApartmentsByTypology
					.map(apartment => {
						if (apartment[self.propertyNames.assetSubtype])
							return apartment[self.propertyNames.assetSubtype].id
					})
					.includes(subType.id)
			)
		},
	},
}
</script>
